import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useSitrepsV2,
  useSitrepV2,
  useInitSitrepV2,
  useRefreshSitrepV2,
  useUpdateSitrepV2,
} from './hooks/useSitrepsV2';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import SitrepSidebarItem from './SitrepSidebarItem';
import { selectGroupGuid, selectIncident } from 'slices/commonSelectors';
import IconClose from 'assets/images/icon__times.svg';
import IconArrowLeft from 'assets/images/icon__arrow--left.svg';
import SimpleEntityList from 'components/DesignSystems/New/SimpleEntityList';
import { useToggle } from 'react-use';
import Sitrep from './Sitrep';
import { MdAdd } from 'react-icons/md';
import SectionTemplateManager from './SectionTemplateManager';
import SitrepTemplateManager from './SitrepTemplateManager';
import CustomDataSourceManager from './CustomDataSourceManager';
import { toast } from 'react-toastify';
import sitrepName from 'components/DisasterGPT/DisasterGPTDataHelpers/sitrepName';
import generateUUID from 'utils/sharedUtils/generateUUID';
import SitrepCreateDrawer from './SitrepCreateDrawer';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';

const SitrepSidebar = ({
  toggleSitrepOpen,
  sitrepOpen,
  onClose,
  selectedDatetime,
  map,
  mapHasLoaded,
  toggleDGPTOpen,
  selectedGeolocation,
  setLayerClicked
}) => {
  const dispatch = useDispatch();
  const group_guid = useSelector(selectGroupGuid);
  const incident = useSelector(selectIncident);

  const { data: sitreps = [], isLoading, isError } = useSitrepsV2();
  const initSitrepMutation = useInitSitrepV2();
  const updateSitrepMutation = useUpdateSitrepV2();
  const refreshSitrepMutation = useRefreshSitrepV2();

  const [showSidebarSecondary, setShowSidebarSecondary] = useState(false);
  const [expanded, toggleExpanded] = useToggle(true);
  const [selectedSitrepId, setSelectedSitrepId] = useState(null);
  const [filterText, setFilterText] = useState('');

  const {
    data: selectedSitrepData,
    isLoading: isSitrepLoading,
    isError: isSitrepError,
    refetch: refetchSitrep,
  } = useSitrepV2(selectedSitrepId);

  const [currentView, setCurrentView] = useState('list');

  const [showCreateDrawer, setShowCreateDrawer] = useState(false);
  const [editingSitrep, setEditingSitrep] = useState(null);

  const handleCreateSitrep = async (formData) => {
    try {
      const sitrepIdResult = await initSitrepMutation.mutateAsync({
        sitrep_template_id: formData.sitrep_template_id || null,
        sitrep_source: 'MANUAL',
        sitrep_source_id: generateUUID(),
        name: formData.name || sitrepName('MANUAL'),
        selectedDatetime: new Date().toISOString(),
        aor_id: formData.aor_id || null
      });
      setSelectedSitrepId(sitrepIdResult);
      setShowSidebarSecondary(true);
      setShowCreateDrawer(false);
      setEditingSitrep(null);
    } catch (e) {
      toast.error('Error creating SITREP: ' + e.message);
    }
  };

  const handleUpdateSitrep = async (formData) => {
    if (!editingSitrep) return;

    try {
      await updateSitrepMutation.mutateAsync({
        sitrep_id: editingSitrep.id,
        name: formData.name,
        aor_id: formData.aor_id,
      });
      if (selectedSitrepId === editingSitrep.id) {
        refetchSitrep();
      }
      setEditingSitrep(null);
      setShowCreateDrawer(false);
    } catch (e) {
      toast.error('Error updating SITREP: ' + e.message);
    }
  };

  const handleSaveSitrep = (formData) => {
    if (editingSitrep) {
      handleUpdateSitrep(formData);
    } else {
      handleCreateSitrep(formData);
    }
  };

  const handleSitrepClick = (sitrep) => {
    setSelectedSitrepId(sitrep.id);
    setShowSidebarSecondary(true);
  };

  const handleEditSitrep = (sitrep) => {
    setEditingSitrep(sitrep);
    setShowCreateDrawer(true);
  };

  const filteredSitreps = useMemo(() => {
    const lowerCaseFilter = filterText.toLowerCase();
    if (lowerCaseFilter) {
      return sitreps.filter((sitrep) => {
        if (typeof sitrep.name === 'string') {
          return sitrep.name.toLowerCase().includes(lowerCaseFilter);
        } else {
          console.warn('Encountered sitrep with invalid name:', sitrep);
          return false;
        }
      });
    } else {
      return sitreps;
    }
  }, [sitreps, filterText]);

  const handleRefreshSitrep = () => {
    refreshSitrepMutation.mutate(
      { id: selectedSitrepId, selectedDatetime, hardRefresh: true },
      {
        onSuccess: () => {
          refetchSitrep(); 
        },
      }
    );
  };

  const views = [
    { label: 'SITREPS', value: 'list' },
    { label: 'SITREP Templates', value: 'sitrepTemplates' },
    { label: 'Section Templates', value: 'sectionTemplates' },
    { label: 'Custom Data', value: 'customDataSources' },
  ];

  if (isError) {
    return (
      <div className="sidebar-content-wide">
        <div className="sidebar-title">
          <h4 className="m-0">SITREPS</h4>
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={onClose}
          >
            <img src={IconClose} alt="Close" />
          </StylishNewButton>
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <span>Error loading sitreps.</span>
        </div>
      </div>
    );
  }

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        {showSidebarSecondary ? (
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={() => {
              setShowSidebarSecondary(false);
              setSelectedSitrepId(null);
            }}
          >
            <img src={IconArrowLeft} alt="Back" />
          </StylishNewButton>
        ) : null}

        <h4 className="m-0">
          SITREPS
        </h4>

        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={onClose}
        >
          <img src={IconClose} alt="Close" />
        </StylishNewButton>
      </div>

      {!showSidebarSecondary && (
        <div style={{ padding: '8px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <StylishNewToggleButtonGroup
            data={views}
            selected={currentView}
            onChange={(val) => setCurrentView(val)}
          />
        </div>
      )}

      {!showSidebarSecondary && currentView === 'list' && (
        <>
          <div
            style={{ display: 'flex', gap: '8px', margin: '8px', alignItems: 'center' }}
          >
            <div style={{ display: 'flex', gap: '8px' }}>
              <StylishNewButton
                className="button--primary"
                onClick={() => {
                  setEditingSitrep(null);
                  setShowCreateDrawer(true);
                }}
              >
                Add
              </StylishNewButton>
            </div>
            <div style={{ flexGrow: 1 }}>
              <StylishNewSearchBar
                value={filterText}
                onChangeSearchTerm={(val) => setFilterText(val)}
                placeholder="Search SITREPs by name..."
              />
            </div>
          </div>
          <hr className="dashed w-10 thin m-0" />
        </>
      )}

      {showCreateDrawer && (
        <SitrepCreateDrawer
          onHide={() => {
            setShowCreateDrawer(false);
            setEditingSitrep(null);
          }}
          onSave={handleSaveSitrep}
          sitrep={editingSitrep}
        />
      )}

      <div
        style={{ flexGrow: 1, overflowY: 'auto', height: 'calc(100% - 64px)' }}
      >
        {currentView === 'sectionTemplates' && !showSidebarSecondary ? (
          <SectionTemplateManager />
        ) : currentView === 'sitrepTemplates' && !showSidebarSecondary ? (
          <SitrepTemplateManager />
        ) : currentView === 'customDataSources' && !showSidebarSecondary ? (
          <CustomDataSourceManager />
        ) : showSidebarSecondary ? (
          isSitrepLoading || refreshSitrepMutation.isLoading ? (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <span>{refreshSitrepMutation.isLoading ? 'Refreshing SITREP...' : 'Loading SITREP details...'}</span>
              <div>
                <i className="fa fa-spinner fa-pulse fs-6"></i>
              </div>
            </div>
          ) : isSitrepError || refreshSitrepMutation.isError ? (
            <div className="text-center" style={{ marginTop: '20px' }}>
              <h5>There was an error with this SITREP.</h5>
              {refreshSitrepMutation.error && <p>{refreshSitrepMutation.error.message || 'Error refreshing SITREP.'}</p>}
              <StylishNewButton onClick={handleRefreshSitrep}>
                Refresh SITREP
              </StylishNewButton>
            </div>
          ) : selectedSitrepData ? (
            <div className="sitrep-wrap" style={{ margin: '16px' }}>
              <Sitrep
                sitrep={selectedSitrepData}
                map={map}
                mapHasLoaded={mapHasLoaded}
                toggleDGPTOpen={toggleDGPTOpen}
                name={selectedSitrepData.name}
                selectedDatetime={selectedDatetime}
                selectedGeolocation={selectedGeolocation}
                setLayerClicked={setLayerClicked}
                fromSidebar={true}
              />
            </div>
          ) : (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <span>No SITREP data available.</span>
            </div>
          )
        ) : currentView === 'list' ? (
          isLoading ? (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <span>Loading...</span>
            </div>
          ) : (
            <>
              {filteredSitreps.length > 0 ? (
                <SimpleEntityList
                  entities={filteredSitreps}
                  header={<></>}
                  renderEntity={(sitrep) => (
                    <SitrepSidebarItem
                      key={sitrep.id}
                      sitrep={sitrep}
                      onClick={() => handleSitrepClick(sitrep)}
                      onEdit={() => handleEditSitrep(sitrep)}
                      isSelected={selectedSitrepId === sitrep.id}
                    />
                  )}
                />
              ) : (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <span>No SITREPs found.</span>
                </div>
              )}
            </>
          )
        ) : null}
      </div>
    </div>
  );
};

export default SitrepSidebar;
