import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDisasterChats, useDeleteDisasterChat } from './hooks/useDisasterChats';
import { setSelectedDChat, setCurrentSitrepId } from 'slices/dchatSlice';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import SimpleEntityList from 'components/DesignSystems/New/SimpleEntityList';
import IconClose from 'assets/images/icon__times.svg';
import PromptLibraryList from './PromptLibraryList';
import { selectGroupGuid } from 'slices/commonSelectors';
import { MdDelete, MdVisibility, MdEdit } from 'react-icons/md';
import { Modal } from 'react-bootstrap';
import DisasterChatEditDrawer from './DisasterChatEditDrawer';

const DisasterChatSidebar = ({ toggleDGPTOpen, DGPTOpen, onClose }) => {
    const dispatch = useDispatch();
    const { data: chats = [], isLoading } = useDisasterChats();
    const group_guid = useSelector(selectGroupGuid);

    const deleteDisasterChat = useDeleteDisasterChat();

    const [currentView, setCurrentView] = useState('disasterChat');
    const [filterText, setFilterText] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [chatToDelete, setChatToDelete] = useState(null);
    const [chatToEdit, setChatToEdit] = useState(null);

    const views = [
        { label: 'DisasterChat', value: 'disasterChat' },
        { label: 'Prompt Library', value: 'promptLibrary' },
    ];

    const handleChatClick = (chat) => {
        const startedOpen = DGPTOpen;
        dispatch(setCurrentSitrepId());
        dispatch(setSelectedDChat(chat));
        toggleDGPTOpen();
        if (startedOpen) {
            toggleDGPTOpen();
        }
    };

    const handleClickClose = () => {
        onClose();
    };

    const handleAddChat = () => {
        // "Add" means start a new chat (empty chat object)
        handleChatClick({});
    };

    const handleDeleteChat = (chat) => {
        setChatToDelete(chat);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        if (chatToDelete) {
            deleteDisasterChat.mutate(chatToDelete.id, {
                onSettled: () => {
                    setShowDeleteModal(false);
                    setChatToDelete(null);
                },
            });
        }
    };

    const handleCancelDelete = () => {
        setShowDeleteModal(false);
        setChatToDelete(null);
    };

    const handleEditChat = (chat) => {
        setChatToEdit(chat);
    };

    const handleCloseEditPanel = () => {
        setChatToEdit(null);
    };

    const filteredChats = useMemo(() => {
        const lowerCaseFilter = filterText.toLowerCase();
        return chats.filter((chat) => {
            if (typeof chat.name === 'string') {
                return chat.name.toLowerCase().includes(lowerCaseFilter);
            } else {
                console.warn('Encountered chat with invalid name:', chat);
                return false;
            }
        });
    }, [chats, filterText]);

    if (currentView === 'promptLibrary') {
        return (
            <div
                className="sidebar-content-wide"
                style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >
                <div className="sidebar-title" style={{ minHeight: '64px' }}>
                    <h4 className="m-0">Prompt Library</h4>
                    <StylishNewButton
                        customButton
                        className={'button--icon'}
                        onClick={() => handleClickClose()}
                    >
                        <img src={IconClose} alt="" />
                    </StylishNewButton>
                </div>
                <div style={{ padding: '8px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <StylishNewToggleButtonGroup
                        data={views}
                        selected={currentView}
                        onChange={(val) => setCurrentView(val)}
                    />
                </div>
                <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                    <PromptLibraryList group_guid={group_guid} />
                </div>
            </div>
        );
    }

    // disasterChat view:
    return (
        <div
            className="sidebar-content-wide"
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
            <div className="sidebar-title" style={{ minHeight: '64px' }}>
                <h4 className="m-0">DisasterChat</h4>
                <StylishNewButton
                    customButton
                    className={'button--icon'}
                    onClick={() => handleClickClose()}
                >
                    <img src={IconClose} alt="" />
                </StylishNewButton>
            </div>
            <div style={{ padding: '8px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <StylishNewToggleButtonGroup
                    data={views}
                    selected={currentView}
                    onChange={(val) => setCurrentView(val)}
                />
            </div>

            <div
                style={{ display: 'flex', gap: '8px', margin: '8px', alignItems: 'center' }}
            >
                <StylishNewButton onClick={handleAddChat} className="button--primary">
                    Add
                </StylishNewButton>
                <div style={{ flexGrow: 1 }}>
                    <StylishNewSearchBar
                        value={filterText}
                        onChangeSearchTerm={(val) => setFilterText(val)}
                        placeholder="Filter chats by name"
                    />
                </div>
            </div>

            <hr className="dashed w-10 thin m-0" />

            <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                {isLoading ? (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <span>Loading...</span>
                    </div>
                ) : filteredChats.length > 0 ? (
                    <SimpleEntityList
                        entities={filteredChats}
                        renderEntity={(chat) => (
                            <div
                                key={chat.id}
                                className="d-flex justify-content-between align-items-center p-2 border-bottom"
                            >
                                <div>
                                    <strong title={chat.name}>
                                        {chat.name.length > 50 ? `${chat.name.slice(0, 50)}...` : chat.name}
                                    </strong>
                                </div>
                                <div>
                                    <StylishNewButton
                                        className="button--secondary me-2"
                                        onClick={() => handleChatClick(chat)}
                                    >
                                        <MdVisibility />
                                    </StylishNewButton>
                                    <StylishNewButton
                                        className="button--secondary me-2"
                                        onClick={() => handleEditChat(chat)}
                                    >
                                        <MdEdit />
                                    </StylishNewButton>
                                    <StylishNewButton
                                        className="button--secondary"
                                        onClick={() => handleDeleteChat(chat)}
                                    >
                                        <MdDelete />
                                    </StylishNewButton>
                                </div>
                            </div>
                        )}
                    />
                ) : (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <span>No chats found.</span>
                    </div>
                )}
            </div>

            <Modal
                show={showDeleteModal}
                onHide={handleCancelDelete}
                centered
                backdrop={true}
                keyboard={true}
            >
                <Modal.Header closeButton closeVariant="white">
                    <Modal.Title>Delete Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Delete this DisasterChat session?
                </Modal.Body>
                <Modal.Footer>
                    <StylishNewButton className="button--secondary" onClick={handleCancelDelete}>
                        Cancel
                    </StylishNewButton>
                    <StylishNewButton className="button--tertiary" onClick={handleConfirmDelete}>
                        Delete
                    </StylishNewButton>
                </Modal.Footer>
            </Modal>

            {chatToEdit && (
                <DisasterChatEditDrawer
                    show={!!chatToEdit}
                    onHide={handleCloseEditPanel}
                    chat={chatToEdit}
                />
            )}
        </div>
    );
};

export default DisasterChatSidebar;
