// SectionTemplateEditDrawer.js

import React, { useEffect, useState } from 'react';
import { Offcanvas, Row } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import DrawerWrapper, { DrawerFooter } from 'components/IAP/DrawerWrapper';
import FormTextField from 'components/IAP/formFields/FormTextField';
import FormTextAreaField from 'components/IAP/formFields/FormTextAreaField';
import FormSelectField from 'components/IAP/formFields/FormSelectField';
import DataSources from 'components/DisasterGPT/DataSources';
import { useAppSelector, selectGroupGuid } from '../../slices/commonSelectors';
import Accordion from 'react-bootstrap/Accordion';
import SOPs from 'components/DisasterGPT/SOPs';
import SharepointFiles from 'components/DisasterGPT/SharepointFiles';
import { selectIncident } from 'slices/commonSelectors';
import FormCheckboxField from 'components/IAP/formFields/FormCheckboxField';
import {section_template_types} from './DisasterGPTConstants'

const SectionTemplateEditDrawer = ({ show, onHide, onSave, template }) => {
  const incident = useAppSelector(selectIncident);
  const hasSharepointLocation = incident?.sharepoint_location && incident?.tenant_id;
  const group_guid = useAppSelector(selectGroupGuid);
  const [docSelections, setDocSelections] = useState([]);
  const [sharepointSelections, setSharepointSelections] = useState([]);
  const isUsingTeams =
    sessionStorage['isUsingTeams'] === true ||
    sessionStorage['isUsingTeams'] === 'true';

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      id: '',
      name: '',
      type: 'manual',
      dataSources: [],
      ai_instructions: '',
      output_format_structured_summary_Paragraph: false,
      output_format_structured_summary_Table: false,
      output_format_structured_summary_List: false,
    },
  });

  const { register, handleSubmit, reset, watch, setValue } = methods;
  const selectedType = watch('type');

  useEffect(() => {
    if (template) {
      const summaryOptions = template?.output_format_structured?.summary_options || [];
      reset({
        id: template.id || '',
        name: template.name || '',
        type: template.type || 'manual',
        dataSources: template.data_sources || [],
        ai_instructions: template.ai_instructions || '',
        output_format_structured_summary_Paragraph: summaryOptions.includes('Paragraph'),
        output_format_structured_summary_Table: summaryOptions.includes('Table'),
        output_format_structured_summary_List: summaryOptions.includes('List'),
      });
    } else {
      reset({
        id: '',
        name: '',
        type: 'manual',
        dataSources: [],
        ai_instructions: '',
        output_format_structured_summary_Paragraph: false,
        output_format_structured_summary_Table: false,
        output_format_structured_summary_List: false,
      });
    }
  }, [template, reset]);

  const handleSaveTemplate = (formData) => {
    const summary_options = [];
    if (formData.output_format_structured_summary_Paragraph) summary_options.push('Paragraph');
    if (formData.output_format_structured_summary_Table) summary_options.push('Table');
    if (formData.output_format_structured_summary_List) summary_options.push('List');

    const templateData = {
      id: formData.id,
      group_guid,
      name: formData.name,
      type: formData.type,
      data_sources: formData.dataSources,
      ai_instructions: formData.ai_instructions,
      output_format_structured: { summary_options },
    };
    onSave(templateData);
  };

  return (
    <DrawerWrapper toggle={onHide} title={template ? 'Edit Section Template' : 'Add Section Template'}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSaveTemplate)} className="d-flex flex-column justify-content-between flex-grow-1">
          <Offcanvas.Body>
            {template && (
              <input type="hidden" {...register('id')} />
            )}

            <Row className="mb-3">
              <FormTextField
                title="Section Name"
                fieldName="name"
                register={register}
                rules={{ required: 'Section name is required' }}
              />
            </Row>

            <Row className="mb-3">
              <FormSelectField
                title="Section Type"
                fieldName="type"
                options={section_template_types}
                register={register}
                rules={{ required: 'Section type is required' }}
              />
            </Row>

            {selectedType === 'ai' && (
              <>
                <Row className="mb-3">
                  <DataSources
                    selections={watch('dataSources')}
                    setSelections={(newSelections) => setValue('dataSources', newSelections)}
                    usingSitrep={true}
                  />
                  <Accordion.Item eventKey='1' className='mt-0'>
                    <SOPs selections={docSelections} setSelections={setDocSelections} />
                  </Accordion.Item>
                  {isUsingTeams && (
                    <Accordion.Item eventKey='2' className='mt-0'>
                      <SharepointFiles selections={sharepointSelections} setSelections={setSharepointSelections} hasSharepointLocation={hasSharepointLocation} />
                    </Accordion.Item>
                  )}
                </Row>

                <Row className="mb-3">
                  <FormTextAreaField
                    title="AI Prompt (optional)"
                    fieldName="ai_instructions"
                    register={register}
                  />
                </Row>

                <Row className="mb-3">
                  <h5>Output Format (Select at least one, or use AI Prompt)</h5>
                  <div className="d-flex flex-column">
                    <FormCheckboxField
                      title="Paragraph"
                      fieldName="output_format_structured_summary_Paragraph"
                    />
                    <FormCheckboxField
                      title="Table"
                      fieldName="output_format_structured_summary_Table"
                    />
                    <FormCheckboxField
                      title="List"
                      fieldName="output_format_structured_summary_List"
                    />
                  </div>
                </Row>
              </>
            )}
          </Offcanvas.Body>

          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton className='button--secondary'  onClick={onHide}>
                Cancel
              </StylishNewButton>
              <StylishNewButton className='button--primary'  type="submit">
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default SectionTemplateEditDrawer;
