import { useCss, useToggle } from 'react-use';

type EntityListProps<T extends any[] = any[]> = {
  entities: T | undefined;
  header: JSX.Element;
  renderEntity: (entity: T[number]) => JSX.Element;
};

const EntityList = <T extends any[]>({
  entities,
  header,
  renderEntity,
}: EntityListProps<T>) => {
    
  const listStyle = useCss({
    marginLeft: '8px',
  });

  return (
    <div className="w-100">
      <div className="w-100 d-flex align-items-center mb-2">
        {header}
      </div>
      <div className={listStyle}>
        {entities?.map((entity) => renderEntity(entity))}
      </div>
    </div>
  );
};

export default EntityList;
