import React from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { MdEdit } from 'react-icons/md';
import * as dayjs from 'dayjs';

const SitrepSidebarItem = ({ sitrep, onClick, onEdit, isSelected }) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center p-2"
      style={{
        background: isSelected ? '#f0f0f0' : 'transparent',
        borderBottom: '1px solid #ccc',
      }}
    >
      <div style={{ flex: 1 }}>
        {/* Clickable Sitrep Name */}
        <button
          onClick={() => onClick(sitrep)}
          style={{
            background: 'none',
            border: 'none',
            padding: 0,
            margin: 0,
            textAlign: 'left',
            cursor: 'pointer',
            width: '100%',
          }}
        >
          <strong style={{ display: 'block', marginBottom: '4px', color: '#007bff', textDecoration: 'underline' }}>
            {sitrep.name}
          </strong>
        </button>
        <em>{dayjs(sitrep.timestamp).utc().format('YYYY-MM-DD HH:mm [UTC]')}</em>
      </div>
      <div className="d-flex align-items-center">
        {/* Edit Button */}
        <StylishNewButton
          className="button--secondary"
          onClick={() => onEdit(sitrep)}
        >
          <MdEdit />
        </StylishNewButton>
      </div>
    </div>
  );
};

export default SitrepSidebarItem;
