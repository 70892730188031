import { FormProvider, useForm } from 'react-hook-form';
import { Offcanvas, Row } from 'react-bootstrap';
import FormTextAreaField from './formFields/FormTextAreaField';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import React from 'react';
import { IncidentInfo, updateIcsInfo } from './hooks/incidentInfoHooks';
import FormCheckboxField from './formFields/FormCheckboxField';
import FormFileField from './formFields/FormFileField';

type IncidentInfoEditProps = {
  toggle: () => void;
  incidentInfo: IncidentInfo;
};

type FormFields = {
  situation_summary: string;
  operational_period_command_emphasis: string;
  special_medical_emergency_procedures: string;
  priorities: string;
  key_decisions_and_procedures: string;
  limitations_and_constraints: string;
  critical_information_requirements: string;
  medical_air_assets_yn: boolean;
  map_sketch_url: string;
  safety_briefing_url: string;
  incident_location: string;
};

const IncidentInfoEditDrawer: React.FC<IncidentInfoEditProps> = ({
  toggle,
  incidentInfo,
}) => {
  const form = useForm<FormFields>({
    defaultValues: {
      ...incidentInfo,
    },
  });

  const { handleSubmit } = form;

  const mutation = updateIcsInfo();

  const updateIncidentInfo = async (formData: FormFields) => {
    const payload = {
      ...formData,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Edit Situation Info">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(updateIncidentInfo)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextAreaField
                title="Summary"
                fieldName="situation_summary"
                defaultValue={incidentInfo.situation_summary}
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Incident Location"
                fieldName="incident_location"
                defaultValue={incidentInfo.incident_location}
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Operational Period Command Emphasis"
                fieldName="operational_period_command_emphasis"
                defaultValue={incidentInfo.operational_period_command_emphasis}
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Special Medical Emergency Procedures"
                fieldName="special_medical_emergency_procedures"
                defaultValue={incidentInfo.special_medical_emergency_procedures}
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Priorities"
                fieldName="priorities"
                defaultValue={incidentInfo.priorities}
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Key Decisions and Procedures"
                fieldName="key_decisions_and_procedures"
                defaultValue={incidentInfo.key_decisions_and_procedures}
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Limitations and Constraints"
                fieldName="limitations_and_constraints"
                defaultValue={incidentInfo.limitations_and_constraints}
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Critical Information Requirements"
                fieldName="critical_information_requirements"
                defaultValue={incidentInfo.critical_information_requirements}
              />
            </Row>
            <Row>
              <FormCheckboxField
                title="Medical Air Assets"
                fieldName="medical_air_assets_yn"
              />
            </Row>
            <Row>
              <FormFileField title={'Map Sketch (ICS 201) (PNG ONLY)'} fieldName={'map_sketch_url'} required_formats={["PNG"]} />
            </Row>
            <Row>
              <FormFileField title={'Safety Plan (ICS 208) (PDF ONLY)'} fieldName={'safety_plan_url'} required_formats={["PDF"]} />
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default IncidentInfoEditDrawer;
