// SitrepTemplateManager.js

import React, { useState } from 'react';
import {
  useSitrepTemplates,
  useCreateSitrepTemplate,
  useUpdateSitrepTemplate,
  useDeleteSitrepTemplate,
} from './hooks/useSitrepTemplates';
import { useSectionTemplates } from './hooks/useSectionTemplates';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import SimpleEntityList from 'components/DesignSystems/New/SimpleEntityList';
import { useToggle } from 'react-use';
import { MdEdit, MdDelete } from 'react-icons/md';
import SitrepTemplateEditDrawer from './SitrepTemplateEditDrawer';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { Modal } from 'react-bootstrap'; // Imported Modal

const SitrepTemplateManager = () => {
  const { data: sitrepTemplates = [], isLoading, isError } = useSitrepTemplates();
  const { data: sectionTemplates = [] } = useSectionTemplates();
  const createSitrepTemplateMutation = useCreateSitrepTemplate();
  const updateSitrepTemplateMutation = useUpdateSitrepTemplate();
  const deleteSitrepTemplateMutation = useDeleteSitrepTemplate();

  const [addDrawerOpen, toggleAddDrawer] = useToggle(false);
  const [editDrawerOpen, toggleEditDrawer] = useToggle(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // New state variables for delete confirmation modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleOpenAddDrawer = () => {
    setSelectedTemplate(null);
    toggleAddDrawer();
  };

  const handleOpenEditDrawer = (template) => {
    setSelectedTemplate(template);
    toggleEditDrawer();
  };

  const handleCloseDrawer = () => {
    setSelectedTemplate(null);
    toggleAddDrawer(false);
    toggleEditDrawer(false);
  };

  const handleSaveTemplate = (templateData) => {
    if (selectedTemplate) {
      updateSitrepTemplateMutation.mutate(templateData, {
        onSuccess: () => {
          toast.success('SITREP template updated successfully.', toastConfig);
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error updating SITREP template:', error);
          toast.error('Failed to update SITREP template.', toastConfig);
        },
      });
    } else {
      createSitrepTemplateMutation.mutate(templateData, {
        onSuccess: () => {
          toast.success('SITREP template created successfully.', toastConfig);
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error creating SITREP template:', error);
          toast.error('Failed to create SITREP template.', toastConfig);
        },
      });
    }
  };

  // Modified handleDeleteTemplate to use modal
  const handleDeleteTemplate = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  // New function to confirm deletion
  const handleConfirmDelete = () => {
    if (deleteId) {
      deleteSitrepTemplateMutation.mutate(deleteId, {
        onSuccess: () => {
          toast.success('SITREP template deleted successfully.', toastConfig);
          setShowDeleteModal(false);
          setDeleteId(null);
        },
        onError: (error) => {
          console.error('Error deleting SITREP template:', error);
          toast.error('Failed to delete SITREP template.', toastConfig);
          setShowDeleteModal(false);
          setDeleteId(null);
        },
      });
    }
  };

  // New function to cancel deletion
  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setDeleteId(null);
  };

  const filteredTemplates = sitrepTemplates.filter((template) =>
    template.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isError) {
    return <div className="error-message">Error loading SITREP templates.</div>;
  }

  return (
    <div>
      <div
        style={{ display: 'flex', gap: '8px', margin: '8px', alignItems: 'center' }}
      >
        <StylishNewButton onClick={handleOpenAddDrawer} className="button--primary">
          Add
        </StylishNewButton>
        <div style={{ flexGrow: 1 }}>
          <StylishNewSearchBar
            value={searchTerm}
            onChangeSearchTerm={(val) => setSearchTerm(val)}
            placeholder="Search SITREP Templates..."
          />
        </div>
      </div>

      {isLoading ? (
        <div>Loading SITREP Templates...</div>
      ) : (
        <SimpleEntityList
          entities={filteredTemplates}
          renderEntity={(template) => (
            <div
              key={template.id}
              className="d-flex justify-content-between align-items-center p-2 border-bottom"
            >
              <div>
                <strong>{template.name}</strong>
              </div>
              <div>
                <StylishNewButton
                  className="button--secondary me-2"
                  onClick={() => handleOpenEditDrawer(template)}
                >
                  <MdEdit />
                </StylishNewButton>
                <StylishNewButton
                  className="button--secondary"
                  onClick={() => handleDeleteTemplate(template.id)}
                >
                  <MdDelete />
                </StylishNewButton>
              </div>
            </div>
          )}
        />
      )}

      {(addDrawerOpen || editDrawerOpen) && (
        <SitrepTemplateEditDrawer
          show={addDrawerOpen || editDrawerOpen}
          onHide={handleCloseDrawer}
          onSave={handleSaveTemplate}
          template={selectedTemplate}
        />
      )}

      {/* New Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={handleCancelDelete}
        centered
        backdrop={true}
        keyboard={true}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Delete SITREP Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this SITREP Template?</Modal.Body>
        <Modal.Footer>
          <StylishNewButton  className="button--secondary" onClick={handleCancelDelete}>
            Cancel
          </StylishNewButton>
          <StylishNewButton
            className="button--tertiary"
            onClick={handleConfirmDelete}
          >
            Delete
          </StylishNewButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SitrepTemplateManager;
