import React, { useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import DrawerWrapper, { DrawerFooter } from 'components/IAP/DrawerWrapper';
import FormTextField from 'components/IAP/formFields/FormTextField';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useUpdateDisasterChat } from './hooks/useDisasterChats';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

const DisasterChatEditPanel = ({ show, onHide, chat }) => {
    const updateDisasterChat = useUpdateDisasterChat();

    const methods = useForm({
        defaultValues: {
            dchat_id: '',
            name: '',
        },
    });
    const { register, handleSubmit, reset } = methods;

    useEffect(() => {
        if (chat) {
            reset({
                dchat_id: chat.id,  // Using dchat_id as per original pattern
                name: chat.name || '',
            });
        }
    }, [chat, reset]);

    const onSubmit = (data) => {
        // Use dchat_id, not id
        updateDisasterChat.mutate(
            { dchat_id: data.dchat_id, name: data.name, archived: data?.archived || false },
            {
                onSuccess: () => {
                    toast.success('Chat updated successfully.', toastConfig);
                    onHide();
                },
                onError: (error) => {
                    console.error('Error updating chat:', error);
                    toast.error('Failed to update chat.', toastConfig);
                },
            }
        );
    };

    console.log("chat",chat)

    return (
        <DrawerWrapper show={show} toggle={onHide} title="Edit Chat Name">
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="d-flex flex-column justify-content-between flex-grow-1"
                >
                    <Offcanvas.Body>
                        <input type="hidden" {...register('dchat_id')} />
                        <FormTextField
                            title="Chat Name"
                            fieldName="name"
                            register={register}
                            rules={{ required: 'Chat name is required' }}
                        />
                    </Offcanvas.Body>
                    <DrawerFooter>
                        <StylishNewButton className="button--secondary me-2" onClick={onHide}>
                            Cancel
                        </StylishNewButton>
                        <StylishNewButton className="button--primary" type="submit">
                            Save
                        </StylishNewButton>
                    </DrawerFooter>
                </form>
            </FormProvider>
        </DrawerWrapper>
    );
};

export default DisasterChatEditPanel;
