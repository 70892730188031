// CustomDataSourceEditDrawer.js

import React, { useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import DrawerWrapper, { DrawerFooter } from 'components/IAP/DrawerWrapper';
import FormTextField from 'components/IAP/formFields/FormTextField';
import FormTextArea from 'components/IAP/formFields/FormTextAreaField';
import FormKeyValuePairs from 'components/IAP/formFields/FormKeyValuePairs';

const CustomDataSourceEditDrawer = ({ show, onHide, onSave, dataSource }) => {
  const methods = useForm({
    mode: 'onChange', // Validate the form on every change
    defaultValues: {
      id: '',
      name: '',
      description: '',
      url: '',
      key_value_pairs: [], // Initialize as an empty array
      header_key_value_pairs: [], // Initialize as an empty array
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = methods;

  useEffect(() => {
    if (dataSource) {
      reset({
        id: dataSource.id || '',
        name: dataSource.name || '',
        description: dataSource.description || '',
        url: dataSource.url || '',
        key_value_pairs: Object.entries(dataSource.key_value_pairs || {}).map(
          ([key, value]) => ({
            key,
            value,
          })
        ),
        header_key_value_pairs: Object.entries(dataSource.header_key_value_pairs || {}).map(
          ([key, value]) => ({
            key,
            value,
          })
        ),
      });
    } else {
      reset({
        id: '',
        name: '',
        description: '',
        url: '',
        key_value_pairs: [],
        header_key_value_pairs: [],
      });
    }
  }, [dataSource, reset]);

  const handleSaveDataSource = (formData) => {
    try {
      const keyValuePairs = formData.key_value_pairs.reduce((acc, pair) => {
        acc[pair.key] = pair.value;
        return acc;
      }, {});

      const headerKeyValuePairs = formData.header_key_value_pairs.reduce(
        (acc, pair) => {
          acc[pair.key] = pair.value;
          return acc;
        },
        {}
      );

      const dataSourceData = {
        id: formData.id,
        name: formData.name,
        description: formData.description,
        url: formData.url,
        key_value_pairs: keyValuePairs,
        header_key_value_pairs: headerKeyValuePairs,
      };
      onSave(dataSourceData);
    } catch (error) {
      toast.error('Error processing key/value pairs.', toastConfig);
    }
  };

  return (
    <DrawerWrapper
      toggle={onHide}
      title={dataSource ? 'Edit Custom Data Source' : 'Add Custom Data Source'}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(handleSaveDataSource)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            {/* Hidden ID Field */}
            {dataSource && <input type="hidden" {...register('id')} />}

            {/* Name Field with Required Validation */}
            <FormTextField
              title="Name"
              fieldName="name"
              register={register}
              rules={{ required: 'Name is required' }}
            />
            {/* Display Name Field Error */}
            {errors.name && (
              <div className="form-text form-error">{errors.name.message}</div>
            )}

            {/* Description Field */}
            <FormTextArea
              title="Description"
              fieldName="description"
              register={register}
            />

            {/* URL Field with Required Validation */}
            <FormTextField
              title="URL"
              fieldName="url"
              register={register}
              rules={{
                required: 'URL is required',
              }}
            />
            {/* Display URL Field Error */}
            {errors.url && (
              <div className="form-text form-error">{errors.url.message}</div>
            )}

            {/* Key/Value Pairs */}
            <FormKeyValuePairs
              title="Key/Value Pairs"
              fieldName="key_value_pairs"
            />

            {/* Header Key/Value Pairs */}
            <FormKeyValuePairs
              title="Header Key/Value Pairs"
              fieldName="header_key_value_pairs"
            />
          </Offcanvas.Body>

          {/* Drawer Footer with Action Buttons */}
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton className='button--secondary' onClick={onHide}>
                Cancel
              </StylishNewButton>
              <StylishNewButton
                className='button--primary'
                type="submit"
                disabled={!isValid} // Disable if form is invalid
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default CustomDataSourceEditDrawer;
